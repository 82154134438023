import GenericPage from './GenericPage'

const SignInSuccess = () => 
    <GenericPage
        h1="Visitor sign-in"
        h2="You have successfully signed in"
        links={[]} 
        button="Home"
        replace={true} />

export default SignInSuccess